import React from "react";
import Jason from "../images/Team/jason_edited.jpg";
import Raneshia from "../images/Team/lawrence_edited.jpg";
import Josh from "../images/Team/josh_edited.jpg";
import Sonya from "../images/Team/sonya-headshot.jpg";
import Dan from "../images/Team/dan-headshot.jpg";
import Chloe from "../images/Team/chloe-headshot.jpg";
import John from "../images/Team/john-headshot.jpg";
import Abigail from "../images/Team/abigail-headshot.jpg";
import Fiona from "../images/Team/Fiona-headshot.jpg";
import Sara from "../images/Team/sara.jpg";

const Team = () => (
  <div>
    <div class="hidden md:block shapedividers_com-1069 bg-gray-900">
      <div class="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-40 lg:pb-32">
        <div class="space-y-12">
          <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 class="uppercase text-3xl font-extrabold text-white tracking-tight sm:text-5xl">
              Meet our team
            </h2>
            <p class="text-xl text-gray-300">
              Collectively, we have over 40 years of experience and expertise.
            </p>
          </div>
          <ul
            role="list"
            class="space-y-4 grid grid-cols-2 sm:gap-6 sm:space-y-0 md:grid-cols-3 lg:gap-8"
          >
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Jason}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Jason Stanard</h3>
                    <p class="text-green-300">Owner/President</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/hyloq/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Raneshia}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Raneshia Lawrence</h3>
                    <p class="text-green-300">VP/Marketing Director</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/raneshialawrence/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Josh}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Joshua Sutherland</h3>
                    <p class="text-green-300">Creative Director</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/jxmedia/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <ul
            role="list"
            class="space-y-4 grid grid-cols-2 sm:gap-6 sm:space-y-0 md:grid-cols-3 md:flex md:justify-center lg:gap-8"
          >
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={John}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">John Stanard</h3>
                    <p class="text-sm text-green-300">Chief of Staff</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/johnstanard/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={Dan}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Dan Wintermeier</h3>
                    <p class="text-sm text-green-300">
                      Jr. Marketing Specialist
                    </p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/daniel-wintermeier-a437b31a2/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={Chloe}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Chloe Byrnes</h3>
                    <p class="text-sm text-green-300">Jr. Developer</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/chloe-byrnes-693065242/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>

          <ul
            role="list"
            class="space-y-4 grid grid-cols-2 sm:gap-6 sm:space-y-0 md:flex md:justify-center lg:gap-8"
          >
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={Abigail}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Abigail Carlig</h3>
                    <p class="text-sm text-green-300">Executive Assistant</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a target="blank" class="text-gray-300">
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={Fiona}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Fiona Stanard</h3>
                    <p class="text-sm text-green-300">Marketing Assistant</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/fionna-stanard-3024541b9/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-30 w-30 rounded-full xl:w-48 xl:h-48"
                  src={Sara}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Sara Friedmeyer</h3>
                    <p class="text-sm text-green-300">Copywriter</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/sara-friedmeyer-4a8409311/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="pt-24 pb-32 block md:hidden bg-gray-900">
      <div class="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-40 lg:pb-32">
        <div class="space-y-12">
          <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 class="uppercase text-3xl font-extrabold text-white tracking-tight sm:text-5xl">
              Meet our team
            </h2>
            <p class="text-xl text-gray-300">
              Collectively, we have over 20 years of experience and expertise.
            </p>
          </div>
          <ul
            role="list"
            class="space-y-4 grid grid-cols-1 sm:gap-6 sm:space-y-0"
          >
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Jason}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Jason Stanard</h3>
                    <p class="text-green-300">Owner/President</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/hyloq/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Raneshia}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Raneshia Lawrence</h3>
                    <p class="text-green-300">VP/Marketing Director</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/raneshialawrence/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                  src={Josh}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Joshua Sutherland</h3>
                    <p class="text-green-300">Creative Director</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/jxmedia/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <ul role="list" class=" grid grid-cols-2 sm:gap-6">
            <li class="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={John}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">John Stanard</h3>
                    <p class="text-sm text-green-300">Chief of Staff</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li></li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 px-6 mt-2 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={Dan}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Dan Wintermeier</h3>
                    <p class="text-sm text-green-300">
                      Jr. Marketing Specialist
                    </p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/daniel-wintermeier-a437b31a2/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 ml-2 mt-2 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={Chloe}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Chloe Byrnes</h3>
                    <p class="text-sm text-green-300">Jr. Developer</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/chloe-byrnes-693065242/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="py-10 px-6 mt-2 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={Abigail}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Abigail Carlig</h3>
                    <p class="text-sm text-green-300">Executive Assistant</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a href="" target="blank" class="text-gray-300">
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 mt-2 ml-2 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={Fiona}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Fiona Stanard</h3>
                    <p class="text-sm text-green-300">Marketing Assistant</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/fionna-stanard-3024541b9/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li class="py-10 mt-2 ml-2 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left">
              <div class="space-y-6 xl:space-y-10">
                <img
                  class="mx-auto h-28 w-28 rounded-full xl:w-56 xl:h-56"
                  src={Sara}
                  alt=""
                />
                <div class="space-y-2 xl:flex xl:items-center xl:justify-between">
                  <div class="font-medium text-lg leading-6 space-y-1">
                    <h3 class="text-white">Sara Friedmeyer</h3>
                    <p class="text-sm text-green-300">Copywriter</p>
                  </div>

                  <ul role="list" class="flex justify-center space-x-5">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/sara-friedmeyer-4a8409311/"
                        target="blank"
                        class="text-gray-400 hover:text-gray-300"
                      >
                        <span class="sr-only">LinkedIn</span>
                        <svg
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default Team;
