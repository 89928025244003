import * as React from "react";
import Portfolio from "../components/Portfolio";
import Team from "../components/Team";
import Layout from "../components/Layout";
import BlogPreview from "../components/BlogPreview";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Consult FGC | Digital Marketing Agency | Website Design & Development
        </title>

        <meta
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        />
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          name="twitter:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          name="twitter:title"
          content="Consult FGC | Digital Marketing &amp; SEO Company"
        ></meta>
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>

      <div class="hero relative">
        <div class="mx-auto">
          <div class="relative pt-6 pb-24 md:pt-0 md:pb-10">
            <div class="absolute inset-0">
              <img
                class="h-full w-full object-cover opacity-40"
                src="https://imgix.cosmicjs.com/2ae7fc40-14c6-11ed-b0e6-bf31569d7c90-home-hero.png"
                alt="Consult Bg"
              />
              <div class="absolute inset-0 bg-gradient-to-r from-gray-900 to-blue-900 mix-blend-multiply"></div>
            </div>

            <div class="px-5 md:px-0 md:flex items-center max-w-7xl mx-auto md:justify-between">
              <div class="relative pt-10 md:pt-0 md:flex justify-between items-center">
                <div class="md:w-2/3">
                  <h1 class="uppercase mt-4 text-3xl tracking-loose font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6">
                    <span class="block">
                      <span class="text-sky-200">f</span>
                      orward-Thinking
                    </span>
                    <span class="block">
                      <span class="text-sky-200">g</span>enuine{" "}
                      <span class="text-sky-200">c</span>reatives
                    </span>
                  </h1>
                  <p class="mt-3 text-lg text-gray-100 sm:mt-8 md:text-xl  xl:text-xl">
                    Consult FGC is utilizing{" "}
                    <span class="italic">real people</span> with{" "}
                    <span class="italic">real expertise</span> to drive{" "}
                    <span class="italic">real results.</span> We focus on
                    extending the marketing team you may or may not already have
                    - merging strategy and skill to identify your pain points,
                    address challenges, and deliver the results your business
                    needs.
                  </p>{" "}
                  <div class="mt-12 md:flex md:justify-start ">
                    <div class="text-center">
                      <a
                        href="/contact-us"
                        class="inline-flex text-blue-900 border-2 border-sky-400 bg-sky-400 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:opacity-80 focus:outline-none"
                      >
                        Contact Us
                      </a>
                    </div>
                    <div class="mt-5 text-center md:mt-0 md:ml-6">
                      <a
                        href="https://www.hyloq.com/"
                        class="inline-flex text-white border-2  border-sky-200 font-medium rounded-lg text-xl px-20 md:px-28 py-4 hover:bg-blue-700 hover:border-blue-700 hover:text-white focus:outline-none"
                      >
                        Hyloq
                      </a>
                    </div>
                  </div>
                </div>
                <div class="hidden md:block lg:w-1/2 ">
                  <img
                    class="animate-pulse mt-10 p-10 mx-auto"
                    src="https://imgix.cosmicjs.com/b9fa8160-1709-11ed-a6f1-796d8d81420f-consult-favicon-white-01.svg"
                    alt="Consult FGC Logo"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="px-5 md:px-0 services pt-20 pb-20">
        <div class="max-w-7xl mx-auto">
          <h2 class="text-3xl font-extrabold text-center text-gray-700 tracking-tight uppercase md:text-5xl">
            Supercharge Your Business
          </h2>
          <p class="mt-4 max-w-2xl mx-auto text-center text-lg text-gray-600">
            Pull back the curtains on digital marketing. We're here to provide
            expert advice to fuel your marketing strategy.
          </p>
          <div class="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 lg:mt-16 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-16">
            <div class="branding">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-amber-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M22 8.51v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm5.521 1.544l1.57-1.743 1.019.918-1.603 1.777c-.25-.297-.593-.672-.986-.952zm-10.738.952l-1.603-1.777 1.019-.918 1.57 1.743c-.392.28-.736.655-.986.952zm-1.597 5.429h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681zm9.462 9.118h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Branding Strategy
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Establish a new online identity for your business or
                    revitalize an old one. We can help you from start to finish.
                  </p>
                </div>
              </div>
            </div>
            <div class="web design">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-purple-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-2-16h-16v11s-.587-1-1.922-1c-1.104 0-2.078.896-2.078 2s.896 2 2 2h18v-14zm-2 12h-12v-10h12v10zm-8-9h-3v8h10v-8h-6v3h6v1h-2v3h-1v-3h-3v3h-1v-7z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Web Design & Development
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    In-house experts ready to build out custom designs &
                    functionality.
                  </p>
                  <p class="opacity-0">-</p>
                </div>
              </div>
            </div>

            <div class="sem">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-lime-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M20.699 11.069c1.947 1.015 3.3 2.81 3.3 4.977 0 1.204-.433 2.387-1.202 3.304-.032 1.075.601 2.618 1.171 3.741-1.529-.277-3.702-.886-4.686-1.49-.833.202-1.631.295-2.385.295-2.919 0-5.185-1.398-6.278-3.271 6.427-.207 9.818-4.482 10.08-7.556zm-10.798-10.07c-5.282 0-10 3.522-10 8.34 0 1.708.615 3.385 1.705 4.687.046 1.525-.851 3.713-1.66 5.307 2.168-.392 5.251-1.257 6.648-2.114 7.696 1.873 13.307-2.837 13.307-7.88 0-4.844-4.751-8.34-10-8.34zm3.048 10.74c-.212.071-.441-.044-.511-.256 0 0-1.496-.501-3.272.088l1.166 1.814c.121.179.037.422-.164.49l-.794.263-.167.028c-.149 0-.294-.064-.396-.179l-1.604-1.77c-.873.276-1.862-.042-2.192-.865-.076-.188-.114-.389-.114-.592 0-.585.327-1.183 1.038-1.533 3.559-1.747 4.128-3.696 4.128-3.696-.086-.262.11-.532.382-.532.169 0 .327.108.383.277l2.371 5.952c.071.212-.044.441-.254.511zm1.687-2.043l-.614-.261c.267-.634.293-1.371.014-2.058-.279-.688-.808-1.199-1.441-1.466l.26-.615c1.265.535 2.046 1.771 2.046 3.091 0 .454-.092.898-.265 1.309zm-1.063-.451l-.599-.254c.154-.365.169-.789.008-1.185-.16-.396-.466-.69-.83-.843l.253-.601c.518.219.952.635 1.179 1.198.229.564.207 1.165-.011 1.685z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    SEM & Digital Marketing
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    We’ll evaluate how to best meet your marketing needs in an
                    online world and get to work.
                  </p>
                </div>
              </div>
            </div>
            <div class="social">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-blue-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Social Media & Marketing
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Social media is more than just a buzzword. We delve into
                    effective strategies to make social work for you.
                  </p>
                </div>
              </div>
            </div>
            <div class="geofence">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-cyan-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M18 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.852-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm-12-3c-2.099 0-4 1.702-4 3.801 0 3.121 3.188 3.451 4 8.199.812-4.748 4-5.078 4-8.199 0-2.099-1.901-3.801-4-3.801zm0 5.333c-.737 0-1.333-.597-1.333-1.333s.596-1.333 1.333-1.333 1.333.596 1.333 1.333-.596 1.333-1.333 1.333zm6 5.775l-3.215-1.078c.365-.634.777-1.128 1.246-1.687l1.969.657 1.92-.64c.388.521.754 1.093 1.081 1.75l-3.001.998zm12 7.892l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 3.62-8.144c.299.76.554 1.776.596 3.583l-.443.996 2.699-1.021 4.809 2.091.751-3.725.718 3.675 4.454-2.042 3.099 1.121-.461-1.055c.026-.392.068-.78.131-1.144.144-.84.345-1.564.585-2.212l3.442 7.877z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">
                    Geofence Advertising
                  </h3>
                  <p class="mt-2 text-base text-gray-600">
                    Add hyperlocal marketing to your marketing tool box with our
                    advanced geofencing platform.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div class="more">
              <div class="bg-teal-50 border border-teal-200 rounded-lg p-10">
                <div>
                  <span class="flex items-center h-15 w-15 rounded-md">
                    <svg
                      class="h-20 w-20 text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-3 11.25c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-.537c-.151-.135-.228-.321-.228-.509 0-.375.304-.682.683-.682.162 0 .324.057.455.173l.746.665 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.307.684.682 0 .163-.059.328-.179.459l-2.116 2.313c-.134.147-.319.222-.504.222-.162 0-.325-.057-.455-.173zm11.772-2.711c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75zm-11.772-1.613v.001c-.151-.135-.228-.322-.228-.509 0-.376.304-.682.683-.682.162 0 .324.057.455.173l.746.664 1.66-1.815c.136-.147.319-.221.504-.221.381 0 .684.308.684.682 0 .164-.059.329-.179.46l-2.116 2.313c-.134.147-.319.221-.504.221-.162 0-.325-.057-.455-.173zm11.772-1.639c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h4.5c.414 0 .75.336.75.75z" />
                    </svg>
                  </span>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-bold text-gray-600">& More</h3>
                  <p class="mt-2 text-base text-gray-600">
                    We are a full-service, digital agency. Contact us to build a
                    custom plan for your company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mt-20 mb-8 ">
          <a
            href="/contact-us"
            class="inline-flex text-sky-100 border-2 border-sky-600 bg-sky-600 font-medium rounded-lg text-xl px-14 md:px-24 py-4 hover:opacity-80 focus:outline-none"
          >
            Get Started Today
          </a>
        </div>
      </div>

      <div class="hyloq relative bg-white md:pt-10 pb-14 overflow-hidden">
        <div class="relative">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 pt-16 md:pb-24 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div class="mt-6">
                  <h2 class="uppercase text-3xl md:text-5xl font-extrabold tracking-tight text-gray-700">
                    Hyloq & Geofence Marketing
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    Advanced geofencing software optimized for hyperlocal
                    marketing. Hyloq is the perfect marriage of data and
                    digital. Hyloq streamlines the process of creating geofences
                    and accessing a large inventory of ad networks to serve ads
                    easily and efficiently.
                  </p>
                  <div class="mt-6">
                    <a
                      href="https://www.hyloq.com/"
                      class="inline-flex px-10 py-2.5 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                    >
                      {" "}
                      Learn More{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div class="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p class="text-sm text-gray-500">
                      "This is an exceptional geo-fencing platform that
                      delivered the control over my ad campaigns that I was
                      actively looking for. HYLOQ offered features and customer
                      demographic data that I’ve not seen before in other
                      platforms. This is truly one of the most powerful tools I
                      will have in my marketing toolbox."
                    </p>
                  </div>
                  <footer class="mt-3">
                    <div class="flex items-center space-x-3">
                      <div class="flex-shrink-0">
                        <img
                          class="h-6 w-6 rounded-full"
                          src="https://imgix.cosmicjs.com/ee50b510-f17f-11ec-8fb8-5d396858ac9b-David-Oak.jpg"
                          alt="David Oak"
                        />
                      </div>
                      <div class="text-base font-medium text-gray-700">
                        David Oak, Chief Marketing Officer, Achieva Credit Union
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0">
              <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://imgix.cosmicjs.com/5f2ce430-4657-11ed-962b-4d4cf7599074-Hyloq-Report.png"
                  alt="HyLoq Placement Inbox"
                />
              </div>
            </div>
          </div>
        </div>{" "}
      </div>

      <Team />
      <div class="logos bg-white pt-28 pb-14 border-b">
        {" "}
        <div class="px-5 max-w-7xl mx-auto">
          <h2 class="uppercase text-3xl md:text-5xl font-extrabold text-center text-gray-700 tracking-tight">
            Companies That Have Put Their Trust In Us
          </h2>

          <Portfolio />
        </div>
      </div>

      <div class="relative bg-white md:pt-10 pb-14 overflow-hidden">
        <div class="relative">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 pt-16 md:pb-24 lg:max-w-none lg:mx-0 lg:px-0">
              <div class="lg:self-center">
                <h2 class="text-3xl md:text-5xl font-bold tracking-tight text-gray-900">
                  <span class="block">Marketing Glossary</span>
                </h2>
                <p class="mt-4 text-lg leading-6 text-gray-400">
                  Take a look at the popular marketing terms and concepts used
                  while developing your business.
                </p>
                <a
                  href="/marketing-terms"
                  class="hidden md:block md:inline-flex mt-8 px-10 py-2.5 text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div class="hidden md:block md:pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="https://imgix.cosmicjs.com/3db937b0-48c1-11ed-a07b-05c6717a9348-Terms-Image.png"
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="md:block md:hidden">
        <div class="lg:relative lg:h-full">
          <img
            class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src="https://imgix.cosmicjs.com/3db937b0-48c1-11ed-a07b-05c6717a9348-Terms-Image.png"
            alt="App screenshot"
          />
          <div>
            <a
              href="/marketing-terms"
              class="mt-8 inline-flex items-center rounded-md bg-white text-white px-5 py-3 text-base font-medium bg-blue-600 shadow hover:bg-blue-700"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>

      <BlogPreview />

      <div class="relative cta bg-blue-600 py-36 ">
        <div class="absolute inset-0">
          <img
            class="h-full w-full object-cover opacity-100"
            src="https://imgix.cosmicjs.com/3e70dfb0-f221-11ec-a2eb-c1653f3f9199-abstract-cta-alt.jpg"
            alt="Cosmic CTA Background"
          />
        </div>
        <div class="relative max-w-6xl mx-auto text-center px-4 pb-4 sm:px-6 lg:px-8">
          <h1 class="uppercase relative text-3xl font-bold text-white sm:text-6xl">
            Ready To Expand Your Business?
          </h1>
          <p class="mt-7 text-lg md:text-3xl leading-6 text-blue-100">
            Start here to request your free website audit.
          </p>

          <div class="mt-16">
            <a
              href="/contact-us"
              class="inline-flex text-white border-2  border-white font-medium rounded-lg text-xl px-20 py-3 bg-white text-blue-700 hover:bg-transparent hover:text-white focus:outline-none"
            >
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
