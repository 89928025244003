import React from "react";

const BlogPreviewItem = ({
  title,
  author,
  date,
  description,
  slug,
  category,
  image,
}) => {
  return (
    <a href={"blog/" + slug} class="hover:opacity-70">
      <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div class="flex-shrink-0">
          <img class="h-64 w-full object-cover" src={image} alt="" />
        </div>

        <div class="flex-1 bg-gradient-to-r from-emerald-700 to-blue-700 p-10 flex flex-col justify-between">
          <div class="flex-1">
            <div class="block mt-2">
              <p class="text-xl font-bold text-white">{title}</p>
              <p class="mt-3 text-base text-gray-50">{description}</p>
            </div>
            <div class="mt-4 w-2/3 border-t"></div>
            <div className="mt-4 flex text-sm leading-5 text-blue-200">
              <time>{date}</time>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default BlogPreviewItem;
