import * as React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import BlogPreviewItem from "../components/BlogPreviewItem";

const BlogPreview = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allCosmicjsPosts(sort: { fields: [published_at], order: DESC }) {
            edges {
              node {
                title
                id
                slug
                content
                published_at(formatString: "DD MMMM, YYYY")
                metadata {
                  post_author
                  tag
                  description
                  main_image {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <div class="relative pt-16 pb-32 px-4 sm:px-6 lg:pt-24 lg:pb-32 lg:px-8">
            <div class="absolute inset-0">
              <div class="bg-white h-1/3 sm:h-2/3"></div>
            </div>
            <div class="relative max-w-7xl mx-auto">
              <div class="text-center">
                <h2 class="uppercase text-3xl tracking-tight font-extrabold text-gray-700 sm:text-5xl">
                  The Consultant's Couch
                </h2>
              </div>
              <div class="hidden md:grid mt-12 max-w-lg mx-auto gap-5 lg:grid-cols-3 lg:max-w-none">
                {data.allCosmicjsPosts.edges.slice(0, 3).map((post) => {
                  console.log(post);
                  return (
                    <BlogPreviewItem
                      key={post.node.id}
                      title={post.node.title}
                      date={post.node.published_at}
                      author={post.node.metadata.post_author}
                      description={post.node.metadata.description}
                      slug={"posts/" + post.node.slug}
                      image={post.node.metadata.main_image.url}
                      category={post.node.metadata.tag.map((tag) => {
                        return (
                          <p class="-mt-2 -mb-2  leading-tight inline-block pr-2 ">
                            {tag + ","}
                          </p>
                        );
                      })}
                    />
                  );
                })}
              </div>
              <div class="md:hidden mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {data.allCosmicjsPosts.edges.slice(0, 1).map((post) => {
                  console.log(post);
                  return (
                    <BlogPreviewItem
                      key={post.node.id}
                      title={post.node.title}
                      date={post.node.published_at}
                      author={post.node.metadata.post_author.title}
                      description={post.node.metadata.description}
                      slug={"posts/" + post.node.slug}
                      image={post.node.metadata.main_image.url}
                      category={post.node.metadata.tag.map((tag) => {
                        return (
                          <p class="-mt-2 -mb-2  leading-tight inline-block pr-2 ">
                            {tag.title + ","}
                          </p>
                        );
                      })}
                    />
                  );
                })}
              </div>
              <div class="mt-20 text-center">
                <a
                  href="/blog"
                  class="w-full text-white bg-emerald-500 font-medium rounded-lg text-xl px-8 md:px-20 py-4 hover:bg-emerald-700 focus:outline-none"
                >
                  Take A Seat On The Couch
                </a>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default BlogPreview;
